// import CryptoJS from 'crypto-js';
import CryptoJS, { AES, enc, HmacSHA1, SHA1, HmacSHA256, MD5 } from 'crypto-js';
import {Settings, AMapSettings, CA} from './settings';
import { randomUUID, ksort, RFC3986EncodeURIComponent } from './utility';

const environment = process.env.NODE_ENV || 'development';

const Base64 = enc.Base64;

const getToken = () => {
  if (Settings.simulation) {
    return Settings.simulation
  }
  return window.localStorage.getItem(Settings.tk) || '';
}

const removeToken = () => {
  window.localStorage.removeItem(Settings.tk);
  window.alert('令牌已过期，请重新登录');
  window.location.reload();
}



const buildSignature = (param) => {
  // console.group('buildSignature');
  let params = ksort(param)
  //params may be a FormData object
  let queryString = Object.keys(params).map((key) => {
    return RFC3986EncodeURIComponent(key) + '=' + RFC3986EncodeURIComponent(params[key])
  }).join('&');

  let hash = HmacSHA1(queryString,  Settings.as);
  // console.debug('hash:', hash.toString());
  let b64 = Base64.stringify(hash);
  // console.debug('b64:', b64);
  // rtrim(strtr(base64_encode($str), '+/', '*-'), '='); //php from server
  let signature = b64.replace(/\+/g, '*').replace(/\//g, '-').replace(/=/g, '');
  // console.debug('signature:', signature);

  // console.groupEnd();
  return signature;
}

const getAppUUID = () => {
  let app_uuid = window.localStorage.getItem('app_uuid');
  if (!app_uuid) {
    app_uuid = randomUUID();
    window.window.localStorage.setItem('app_uuid', app_uuid);
  }
  return app_uuid;
}
const buildSysParams= () => {
  let params = ksort(getSystemParams());
  let queryString = Object.keys(params).map((key) => {
    return RFC3986EncodeURIComponent(key) + '=' + RFC3986EncodeURIComponent(params[key])
  }).join('&');

  let hash = '';
  switch (params.sig_method) {
    case 'HMAC-SHA256': hash = HmacSHA256(queryString,  Settings.as); break;
    case 'SHA1':        hash = SHA1(queryString);                     break;
    case 'HMAC-SHA1':
    default:            hash = HmacSHA1(queryString,    Settings.as); break;
  }
  let b64 = Base64.stringify(hash);
  let signature = b64.replace(/\+/g, '*').replace(/\//g, '-').replace(/=/g, '');
  return { ...params, signature: signature };
}


const buildDataParams = (params = {}) => {
  //CryptoJS AES default mode is CBC, padding is Pkcs7
  // Settings.ds = '1234567890abcdef';
  let key = CryptoJS.enc.Utf8.parse(Settings.ds);
  // let iv = CryptoJS.lib.WordArray.random(16);
  let options = { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7};
      // options = { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: enc.Hex.parse(iv.toString())};
  let plaintext = JSON.stringify(params);
  // let encrypt = iv.concat(AES.encrypt(plaintext, key, options));
  let encrypt = AES.encrypt(plaintext, key, options);
      
  let json =  {data: encrypt.toString().replace(/\+/g, '*').replace(/\//g, '-').replace(/=/g, '')};

  // if (environment === 'development') {
  //   console.group(`ASE.ECB.Pkcs7`);
  //   console.debug('params:', params);
  //   console.debug('plain:', plaintext);
  //   console.debug('encrypt:', encrypt.toString());
  //   console.groupEnd()
  // }
  return json;
}


const getSystemParams = () => {
  return {
    sig_method: 'HMAC-SHA1',
    app_key: Settings.ak,
    app_uuid: getAppUUID(),
    req_uuid: randomUUID(),
    timestamp: parseInt(new Date().getTime() / 1000),
  };
}

const adaptor = (params) => {
  let sysParams = getSystemParams();
  let newParams = {...params, ...sysParams};
  let signature = buildSignature(newParams);
  return { ...newParams, signature: signature };
}


export const storagePrimaryAK = (ak, as, ds) => {
  let hn = window.location.hostname;
  let hn_md5    = MD5(hn);
  let b64_ak_as = AES.encrypt(JSON.stringify([ak, as, ds]), hn_md5.toString(), {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7}).toString();
  window.window.localStorage.setItem(AMapSettings.token, b64_ak_as)
  Settings.ak = ak;
  Settings.as = as;
  Settings.ds = ds;
}



export const validLicenseSettings = () => {
  let at = window.window.localStorage.getItem(AMapSettings.token);
  let hn = window.location.hostname;
  hn = hn === '' ? 'localhost' : hn;
  let ca = CA.split("\n");
  let md5 = MD5(hn);
  if (!at) {
    let idx = Base64.stringify(md5).replace(/=/g, '');
    let i   = ca.findIndex((line) => line.indexOf(idx) !== -1);
    at = i !== -1  ? ca[i].substring(idx.length) : null;
  }
  if (!at) { return null;}

  let str =  AES.decrypt(at, md5.toString(), {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7}).toString(enc.Utf8);
  if (!str){ return null;}

  let ret = JSON.parse(str);
  if ((typeof ret === 'object') && (ret.length === 3)) {
    Settings.ak = ret[0];
    Settings.as = ret[1];
    Settings.ds = ret[2];
  }
  return Settings;
}



export default class Api {

  static buildUrl = (uri, param) => {
    let params = adaptor(param);
    let url = Settings.ah + uri + '?' + new URLSearchParams(params).toString();
    return url;
  }

  // url?sig_method=HMAC-SHA1&app_key=xxx&app_uuid=xxx&req_uuid=xxx&timestamp=xxx&signature=xxx&data=encripted
  static get = async (uri, params = {}) => {
    let sysParams = buildSysParams();
    let datParams = buildDataParams(params);
    let newParams = {...sysParams, ...datParams};
    let url = Settings.ah + uri + '?' + new URLSearchParams(newParams).toString();
  
    let headers = {
      "Accept": "application/json",
    };
    if (getToken()) {
      headers.Authorization = "Bearer " + getToken(); //Access-Control-Allow-Headers: Authorization
    }
    let oHeaders = new Headers(headers);
    let oRequest = new Request(url, {
      method: 'GET',
      mode: 'cors',
      headers: oHeaders,
    });



    let res = await fetch(oRequest).then((response) => {
      if (response.status === 401) {
        removeToken();
      }
      return response.json();
    });

    //*
    if (environment === 'development') {
    console.group(`GET ${Settings.ah + uri}`);
    console.debug('req:', params);
    console.debug('res:', res);
    console.groupEnd()
    }
    //*/
    return res;
  } //end get

  // url?sig_method=HMAC-SHA1&app_key=xxx&app_uuid=xxx&req_uuid=xxx&timestamp=xxx&signature=xxx
  // postBoy: data=encrypted 
  static post = async (uri, params = {}) => {
    let sysParams = buildSysParams();
    let datParams = buildDataParams(params);
    let url = Settings.ah + uri + '?' + new URLSearchParams(sysParams).toString();
  
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded", //use default for simple fetch
      "Accept": "application/json",
    };
    if (getToken()) {
      headers.Authorization = "Bearer " + getToken(); //Access-Control-Allow-Headers: Authorization
    }
    let oHeaders = new Headers(headers);
    let oRequest = new Request(url, {
      method: 'POST',
      mode: 'cors',
      headers: oHeaders,
      body: new URLSearchParams(datParams).toString(), //Content-Type: application/x-www-form-urlencoded, body: new URLSearchParams(datParams).toString()
    });

    let res = await fetch(oRequest).then((response) => {
      if (response.status === 401) {
        removeToken();
      }
      return response.json();
    });
    if (environment === 'development') {
    console.group(`POST ${url}`);
    console.debug('req:', params);
    console.debug('res:', res);
    console.groupEnd()
    }
    return res;
  } //end post

  // url?sig_method=HMAC-SHA1&app_key=xxx&app_uuid=xxx&req_uuid=xxx&timestamp=xxx&signature=xxx
  // postBoy: data=encrypted
  // postBoy: file=File
  static formpost = async (uri, formData) => {

    let sysParams = buildSysParams();
    let url = Settings.ah + uri + '?' + new URLSearchParams(sysParams).toString();


    let params = {};
    for(let [k, v] of formData) {
      if (!(v instanceof File) && !(v instanceof Blob)) {
        params[k] = v; // only params 
      } else {
        // console.log('file', k, v);
      }
    }

    // // remove params from formData
    Object.keys(params).forEach((key) => formData.delete(key));
    
    // build encrypted data
    let datParams = buildDataParams(params);
    
    // append data to formData
    formData.append('data', datParams.data);

    let headers = {
      "Accept": "application/json",
    };
    if (getToken()) {
      headers.Authorization = "Bearer " + getToken(); //Access-Control-Allow-Headers: Authorization
    }
    let oHeaders = new Headers(headers);
    let oRequest = new Request(url, {
      method: 'POST',
      mode: 'cors',
      headers: oHeaders,
      body: formData, //Content-Type: multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW
    });

    let res = await fetch(oRequest).then((response) => {
      if (response.status === 401) {
        removeToken();
      }
      return response.json();
    });
    if (environment === 'development') {
    console.group(`POST ${uri}`);
    console.debug('params', params);
    console.debug('req', Object.fromEntries(formData));
    console.debug('res', res);
    console.groupEnd()
    }
    return res;
  }//end formpost
  
  // url?sig_method=HMAC-SHA1&app_key=xxx&app_uuid=xxx&req_uuid=xxx&timestamp=xxx&signature=xxx
  // postBoy: {data:encrypted}
  static put = async (uri, params = {}) => {
    let sysParams = buildSysParams();
    let datParams = buildDataParams(params);
    let url = Settings.ah + uri + '?' + new URLSearchParams(sysParams).toString();

    let headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
    };
    if (getToken()) {
      headers.Authorization = "Bearer " + getToken(); //Access-Control-Allow-Headers: Authorization
    }
    let oHeaders = new Headers(headers);

    let oRequest = new Request(url, {
      method: 'PUT',
      mode: 'cors',
      headers: oHeaders,
      body: JSON.stringify(datParams), //Content-Type: application/json
    });

    let res = await fetch(oRequest).then((response) => {
      if (response.status === 401) {
        removeToken();
      }
      return response.json();
    });
    if (environment === 'development') {
    console.group(`PUT ${Settings.ah + uri}`);
    console.debug('req:', params);
    console.debug('res:', res);
    console.groupEnd()
    }
    return res;
  } //end put

  static delete = async (uri, params) => {
    let sysParams = buildSysParams();
    let datParams = buildDataParams(params);
    let newParams = {...sysParams, ...datParams};
    let url = Settings.ah + uri + '?' + new URLSearchParams(newParams).toString();

    let headers = {
      // "Content-Type": "application/json", //use default for simple fetch
      "Accept": "application/json",
    }
    if (getToken()) {
      headers.Authorization = "Bearer " + getToken();
    }
    let oHeaders = new Headers(headers);

    let oRequest = new Request(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: oHeaders,
      body: JSON.stringify(params), //Content-Type: application/json
    });

    let res = await fetch(oRequest).then((response) => {
        console.log('response', response);
        switch (response.status) {
            case 401: removeToken(); break;
            case 204: return {status: 204, message: 'No Content', code: 0};
            default:  return response.json();
        }
    });
    if (environment === 'development') {
    console.group(`DELETE ${Settings.ah + uri}`);
    console.debug('req:', params);
    console.debug('res:', res);
    console.groupEnd()
    }
    return res;
  } // end delete
}


