

import { formatTimestampToDate } from '../utils/utility';
import Api from '../utils/api';
// const Region = require('../data/region.json');

const formatSiteCalendar = (opentime, closetime) => {
    if (formatTimestampToDate(opentime, 'Y/M/D') === formatTimestampToDate(closetime, 'Y/M/D')) {
        return formatTimestampToDate(opentime, 'Y/M/D h:m') + '至' + formatTimestampToDate(closetime, 'h:m');
    }
    else if (formatTimestampToDate(opentime, 'Y') === formatTimestampToDate(closetime, 'Y')) {
        return formatTimestampToDate(opentime, 'Y/M/D') + '至' + formatTimestampToDate(closetime, 'M/D');
    }
    else {
        return formatTimestampToDate(opentime, 'Y/M/D') + '至' + formatTimestampToDate(closetime, 'Y/M/D');
    }
}


export const dataSiteFormatter = (site) => {
    return {
        ...site,
        key: site.id,
        // logourl: site.logourl ? site.logourl : siteCover,
        calendar: formatSiteCalendar(site.starttime, site.closetime),
        isfinished:site.closetime < new Date().getTime()/1000 ? true:false,
    }
}

export const dataHeroFormatter = (ticket) => {
    return {
        ...ticket,
        key: ticket.id,
        title: ticket.fullname,
        fullname: ticket.fullname ? ticket.fullname : ticket.name,
        salesched: formatSiteCalendar(ticket.salestart, ticket.saleclose),
        chkinsched: formatSiteCalendar(ticket.chkinstart, ticket.chkinclose),
    }
}

export const dataBrandFormatter = (brand) => {
    return {
        ...brand,
        key: brand.id,
        // logourl: brand.logourl ? brand.logourl : brandCover,
        isfinished:brand.closetime < new Date().getTime()/1000 ? true:false,
    }
}

export const getSites = async (params = {}) => {
    return await Api.get('/sites', params).then((res) => {
        return res;
    })
}


export const getSite = async (domain) => {
    return await Api.get(`/sites/${domain}`, {id:domain}).then((res) => {
        return res;
    })
}


export const getSiteHeros = async (domain) => {
    return await Api.get(`/sites/${domain}/heros`, { domain }).then((res) => {
        return res;
    });
}


const formatter = new Intl.NumberFormat('es-EC', {
    style: 'currency',
    currency: 'USD'
  });
  
//   console.log(formatter.format(amount)); // 输出 $10,000.00

export const formatTutto = (tutto) => {
    return {
        ...tutto,
        price: formatter.format(tutto.price),
        key: tutto.id,
        // starttime: formatTimestampToDate(tutto.starttime, 'Y/M/D h:m'),
        // endtime: formatTimestampToDate(tutto.endtime, 'Y/M/D h:m'),
    }
}

export const getTuttos = async (params = {}) => {
    return await Api.get('/tuttos', params).then((res) => {
        return res;
    })
}


export const getTutto = async (id) => {
    return await Api.get(`/tuttos/${id}`, {id}).then((res) => {
        return res;
    })
}

export const getTuttoHtml = async (id) => {
    return await Api.get(`/tuttos/${id}/html`, {id}).then((res) => {
        return res;
    })
}


export const getBrands = async (params = {}) => {
    return await Api.get('/brands', params).then((res) => {
        return res;
    })
}