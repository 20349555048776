import React from 'react'
import { useTranslation } from 'react-i18next';
import { MapPinIcon, EnvelopeIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
// import { FaWhatsapp } from 'react-icons/fa';

// import Markdown from 'react-markdown'
// import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
// // import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
// // import { dark, dracula, prism,}from 'react-syntax-highlighter/dist/cjs/styles/prism';
// import { dracula }from 'react-syntax-highlighter/dist/cjs/styles/prism';
// import remarkGfm from 'remark-gfm'

// import styles from './md.module.css'
export default function Shop() {

  const { t } = useTranslation();

  return (
    <article className="prose prose-slate max-w-none prose-th:bg-blue-500 prose-th:text-white prose-table:!table  prose-table:!w-full">
      <div className="flex items-baseline justify-between border-b border-gray-200 px-4 pb-6 pt-14">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900">{t('shop.title')}</h1>
      </div>
      
      <iframe
        title='google map'
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.9011963813364!2d-78.5001740555725!3d-0.16189600000000617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59bc59b127625%3A0xae00d3e58e18b26e!2sJade%20Cafe%20Desing!5e0!3m2!1szh-CN!2sec!4v1702494178183!5m2!1szh-CN!2sec"
        className='w-full h-[500px]'
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>


      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 gap-2 overflow-hidden sm:mx-0 sm:rounded-sm md:grid-cols-3 bg-white">
          <div className="p-4 flex justify-center items-center ring-1 ring-inset ring-gray-300">
            <MapPinIcon className="h-8 w-8 text-tutto-primary" />
            <h2 className="text-center text-base font-semibold leading-8 text-tutto-primary">
              Centro Comercial El Bosque, local 234
            </h2>
          </div>
          <div className="p-4 flex justify-center items-center ring-1 ring-inset ring-gray-300">
            <ChatBubbleLeftRightIcon className="h-8 w-8 text-tutto-primary" />
            <h2 className="text-center text-base font-semibold leading-8 text-tutto-primary">
              0995379469
            </h2>
          </div>
          <div className="p-4 flex justify-center items-center ring-1 ring-inset ring-gray-300">
            <EnvelopeIcon className="h-8 w-8 text-tutto-primary" />
            <h2 className="text-center text-base font-semibold leading-8 text-tutto-primary">
              quito@tuttocapsule.com
            </h2>
          </div>
        </div>
      </div>
    </article>
  );

}

