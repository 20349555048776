import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'


import carts from "../../data/carts.json"

export default function Example() {
  return (

    <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >
        <Popover.Panel className="absolute inset-x-0 top-16 mt-px bg-white pb-6 shadow-lg sm:px-2 lg:left-auto lg:right-0 lg:top-full lg:-mr-1.5 lg:mt-3 lg:w-80 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5">
        <h2 className="sr-only">Shopping Cart</h2>

        <form className="mx-auto max-w-2xl px-4">
            <ul className="divide-y divide-gray-200">
            {carts.map((product) => (
                <li key={product.id} className="flex items-center py-6">
                <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-16 w-16 flex-none rounded-md border border-gray-200"
                />
                <div className="ml-4 flex-auto">
                    <h3 className="font-medium text-gray-900">
                    <a href={product.href}>{product.name}</a>
                    </h3>
                    <p className="text-gray-500">{product.color}</p>
                </div>
                </li>
            ))}
            </ul>

            <button
            type="submit"
            className="w-full rounded-md border border-transparent bg-tutto-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-tutto-secondary focus:outline-none focus:ring-2 focus:ring-tutto-primary focus:ring-offset-2 focus:ring-offset-gray-50"
            >
            Checkout
            </button>

            <p className="mt-6 text-center">
            <Link to="/carts" className="text-sm font-medium text-tutto-primary hover:text-tutto-secondary">
                View  carts
            </Link>
            </p>
        </form>
        </Popover.Panel>
    </Transition>
  )
}