// const environment = process.env.NODE_ENV || 'development';

const CA =`\
-----BEGIN CERTIFICATE-----
-----END CERTIFICATE-----
`;


// 'community id: ''6585e7a9-0000-0000-987a-3ed9e26877b3'
// 'community/database instance id: ''6585e7a9-0000-0000-987a-3ed9e26877b3'
// 'community/database instance dsn: ''mongodb://db1.intranet.zwsrc.com:27017/tuttocapsule-ec'
// 'app id/key: ''6585e7a9-0000-0000-b9d7-5486c5b07584'
// 'app secret: ''6585e7a9-0000-0000-ffdc-6cd5024f733b'
// 'app datkey: ''ffdc6cd5024f733b'


const Settings = {
    simulation: false,
    tk: 'tuttotoken',
    ah: 'https://devapi.betasys.cn/v2', //for portal  rw
    // ak: ['please', 'contact', 'joe#wzhu.net'].join('-'), 
    // as: ['for', 'a', 'license'].join('-'),
    // ds: ['all', 'rights', 'reserved'].join('-'),
    ak: '6585e7a9-0000-0000-b9d7-5486c5b07584',
    as: '6585e7a9-0000-0000-ffdc-6cd5024f733b',
    ds: 'ffdc6cd5024f733b',
}

const AMapSettings = {
    key: "8210f1c05ea676120e284a84c1106a1c", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0",                          // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    token: '_AMap_Token',
    // plugins: ['AMap.AutoComplete'],
    // plugins:['AMap.ToolBar','AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Geocoder'],  // 需要使用的的插件列表，如比例尺'AMap.Scale'等
};

const Enums = {
    'user_types'         :[{value:0, label:"请刷新以重新加载"}], //用户类型
    'auth_types'         :[{value:0, label:"请刷新以重新加载"}], //账户类型
    "event_types"        :[{value:0, label:"请刷新以重新加载"}], //活动类型
    "ticket_types"       :[{value:0, label:"请刷新以重新加载"}], //票务类型
    "institution_types"  :[{value:0, label:"请刷新以重新加载"}], //机构类型
    "institution_levels" :[{value:0, label:"请刷新以重新加载"}], //机构级别 
    'collaborator_levels':[{value:0, label:"请刷新以重新加载"}], //合作伙伴级别
    "folder_tags"        :[{value:0, label:"请刷新以重新加载"}], //文件夹标签
    "entry_statuses"     :[{value:0, label:"请刷新以重新加载"}], //作品状态
    "entry_levels"       :[{value:0, label:"请刷新以重新加载"}], //作品级别
    "entry_groups"       :[{value:0, label:"请刷新以重新加载"}], //作品赛道
}

const Categories = {
    'entry' :  [{value:0, label:"请刷新以重新加载"}],
};



export { Settings, AMapSettings, CA, Enums, Categories };
