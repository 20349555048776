
import React, {Fragment, useState } from 'react'
import {useTranslation} from 'react-i18next'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, FunnelIcon, Squares2X2Icon } from '@heroicons/react/20/solid'
import { StarIcon } from '@heroicons/react/20/solid'

import { getTuttos, formatTutto } from '../../api/site'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// export default function Products() {
//   return (
//     <div className="bg-white">
//       <div className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8">

//       </div>
//     </div>
//   )
// }


export default function Products() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  const {t} = useTranslation();

  // const products = [
  //   {
  //     id: 1,
  //     name: 'Organize Basic Set (Walnut)',
  //     price: '$149',
  //     rating: 5,
  //     reviewCount: 38,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-01.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 2,
  //     name: 'Organize Pen Holder',
  //     price: '$15',
  //     rating: 5,
  //     reviewCount: 18,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-02.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 3,
  //     name: 'Organize Sticky Note Holder',
  //     price: '$15',
  //     rating: 5,
  //     reviewCount: 14,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-03.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 4,
  //     name: 'Organize Phone Holder',
  //     price: '$15',
  //     rating: 4,
  //     reviewCount: 21,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  
  //   {
  //     id: 5,
  //     name: 'Organize Basic Set (Walnut)',
  //     price: '$149',
  //     rating: 5,
  //     reviewCount: 38,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-01.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 6,
  //     name: 'Organize Pen Holder',
  //     price: '$15',
  //     rating: 5,
  //     reviewCount: 18,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-02.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 7,
  //     name: 'Organize Sticky Note Holder',
  //     price: '$15',
  //     rating: 5,
  //     reviewCount: 14,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-03.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 8,
  //     name: 'Organize Phone Holder',
  //     price: '$15',
  //     rating: 4,
  //     reviewCount: 21,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 9,
  //     name: 'Organize Basic Set (Walnut)',
  //     price: '$149',
  //     rating: 5,
  //     reviewCount: 38,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-01.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 10,
  //     name: 'Organize Pen Holder',
  //     price: '$15',
  //     rating: 5,
  //     reviewCount: 18,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-02.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 11,
  //     name: 'Organize Sticky Note Holder',
  //     price: '$15',
  //     rating: 5,
  //     reviewCount: 14,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-03.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 12,
  //     name: 'Organize Phone Holder',
  //     price: '$15',
  //     rating: 4,
  //     reviewCount: 21,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  
  //   {
  //     id: 13,
  //     name: 'Organize Basic Set (Walnut)',
  //     price: '$149',
  //     rating: 5,
  //     reviewCount: 38,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-01.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 14,
  //     name: 'Organize Pen Holder',
  //     price: '$15',
  //     rating: 5,
  //     reviewCount: 18,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-02.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 15,
  //     name: 'Organize Sticky Note Holder',
  //     price: '$15',
  //     rating: 5,
  //     reviewCount: 14,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-03.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   {
  //     id: 16,
  //     name: 'Organize Phone Holder',
  //     price: '$15',
  //     rating: 4,
  //     reviewCount: 21,
  //     imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg',
  //     imageAlt: 'TODO',
  //     href: '/products/overview',
  //   },
  //   // More products...
  // ]
  

  const [products, setProducts] = React.useState([]);

  const sortOptions = [
    { name: 'Most Popular', href: '#', current: true },
    { name: 'Best Rating', href: '#', current: false },
    { name: 'Newest', href: '#', current: false },
    { name: 'Price: Low to High', href: '#', current: false },
    { name: 'Price: High to Low', href: '#', current: false },
  ]
  const subCategories = [
    { name: 'Totes', href: '#' },
    { name: 'Backpacks', href: '#' },
    { name: 'Travel Bags', href: '#' },
    { name: 'Hip Bags', href: '#' },
    { name: 'Laptop Sleeves', href: '#' },
  ]
  // const filters = [
  //   {
  //     id: 'color',
  //     name: 'Color',
  //     options: [
  //       { value: 'white', label: 'White', checked: false },
  //       { value: 'beige', label: 'Beige', checked: false },
  //       { value: 'blue', label: 'Blue', checked: true },
  //       { value: 'brown', label: 'Brown', checked: false },
  //       { value: 'green', label: 'Green', checked: false },
  //       { value: 'purple', label: 'Purple', checked: false },
  //     ],
  //   },
  //   {
  //     id: 'category',
  //     name: 'Category',
  //     options: [
  //       { value: 'new-arrivals', label: 'New Arrivals', checked: false },
  //       { value: 'sale', label: 'Sale', checked: false },
  //       { value: 'travel', label: 'Travel', checked: true },
  //       { value: 'organization', label: 'Organization', checked: false },
  //       { value: 'accessories', label: 'Accessories', checked: false },
  //     ],
  //   },
  //   {
  //     id: 'size',
  //     name: 'Size',
  //     options: [
  //       { value: '2l', label: '2L', checked: false },
  //       { value: '6l', label: '6L', checked: false },
  //       { value: '12l', label: '12L', checked: false },
  //       { value: '18l', label: '18L', checked: false },
  //       { value: '20l', label: '20L', checked: false },
  //       { value: '40l', label: '40L', checked: true },
  //     ],
  //   },
  // ]


  React.useEffect(() => {
    let params ={filter:{status:100}, sort:{}, start: 0, limit: 10}
    getTuttos(params).then(res => {
      let products = res.list.map(item => formatTutto(item));
      setProducts(products);
    })
    // console.log(products)
  }, [])
  

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Categories</h3>
                    <ul className="px-2 py-3 font-medium text-gray-900">
                      {subCategories.map((category) => (
                        <li key={category.name}>
                          <a href={category.href} className="block px-2 py-3">
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">{t('products.title')}</h1>

            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7 hidden">
                <span className="sr-only">View grid</span>
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                <h3 className="sr-only">Categories</h3>
                <ul className="pb-6 text-sm font-medium text-gray-900">
                  {subCategories.map((category) => (
                    <li key={category.name} className="border-b border-gray-200">
                      <a href={category.href} className="block hover:bg-tutto-primary hover:text-white p-2" >{category.name}</a>
                    </li>
                  ))}
                </ul>

              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">

                <h2 className="sr-only">Products</h2>

                <div className="-mx-px grid grid-cols-2 border-l border-gray-200 sm:mx-0 md:grid-cols-2 lg:grid-cols-3">
                {products.map((product) => (
                  <div key={product.id} className="group relative border-b border-r border-gray-200 p-4 sm:p-6">
                    <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg group-hover:opacity-75 h-[200px]">
                        <img
                        src={product.coverurl}
                        alt={product.imageAlt}
                        className="h-full  w-full object-contain object-center"
                        />
                    </div>
                    <div className="pb-4 pt-10 text-center">
                        <h3 className="text-md font-seimibold text-tutto-info">
                        <a href={`/products/${product.id}`}>
                            <span aria-hidden="true" className="absolute inset-0" />
                            {product.fullname}
                        </a>
                        </h3>
                        <div className="mt-3 flex flex-col items-center">
                        <p className="sr-only">{product.star} out of 5 stars</p>
                        <div className="flex items-center">
                            {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                                key={rating}
                                className={classNames(
                                product.star > rating ? 'text-tutto-warning' : 'text-gray-200',
                                'h-5 w-5 flex-shrink-0'
                                )}
                                aria-hidden="true"
                            />
                            ))}
                        </div>
                        </div>
                        <p className="mt-4 text-base font-semibold text-tutto-danger">{product.price}</p>
                        <a
                  href={`/products/${product.id}`}
                  className="relative flex items-center justify-center  border border-transparent bg-tutto-primary text-white px-8 py-2 text-sm font-medium hover:bg-tutto-secondary"
                >
                  view details<span className="sr-only">, {product.fullname}</span>
                </a>
                    </div>
                  </div>
                ))}
                </div>

              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  )
}
