import React from 'react'
import {useTranslation} from "react-i18next";
import site from "../../data/site.json"

import { getBrands } from '../../api/site';

export default function Brands() {

    
    const [brands, setBrands] = React.useState(site.brands);

    const {t} = useTranslation();

    React.useEffect(() => {
        getBrands({filter:{status:100},num:24}).then(data => {
            setBrands(data.list);
        });
    }, []);

    return (

        <div className="py-8">
            <div className="mx-6 lg:mx-8 max-w-7xl border-t border-b border-tutto-primary">
                <h2 className="text-center text-2xl font-semibold leading-8 text-tutto-primary py-4">
                    {t("home.section_brands")}
                </h2>
                <div className="-mx-6 grid grid-cols-3 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-sm md:grid-cols-12">
                    {brands.map((brand) => (
                        <div className="p-2 flex justify-center items-center" key={brand.id}>
                            <img
                                className="object-contain"
                                src={brand.logourl}
                                alt={brand.name}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}
