import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { validLicenseSettings } from './utils/api.js';
import router from "./routes/app.js";


import {useTranslation} from 'react-i18next';
import "./i18n/index.js";

validLicenseSettings();

function App() {



  const {i18n} = useTranslation();

  const getLanguage = () => {
    console.log(i18n.language)
  }

  React.useEffect(() => {
    getLanguage();
    // eslint-disable-next-line
  }, [i18n.language]);

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </React.Suspense>
  );
}

export default App;
