import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
// import "./i18n/index.js";

import bgFooter from "../assets/images/footer.jpg"

import { FaFacebook, FaTwitter, FaTiktok, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";

import site from "../data/site.json"

export default function Footer() {

  const { t } = useTranslation();
  const navigation = {
    main: [
      { name: t("navigation.home"), href: '/' },
      { name: t("navigation.products"), href: '/products' },
      { name: t("navigation.about"), href: '/about' },
      { name: t("navigation.contact"), href: '/contact' },
      { name: t("navigation.shop"), href: '/shop' },
    ],
  }
  return (
    <footer className="bg-tutto-primary" style={{ backgroundImage: `url(${bgFooter})` }}>

      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer" >
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <Link to={item.href} className="text-sm leading-6 text-white hover:text-white">
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {site.socials.map((item) => (
            <a key={item.key} href={item.link} className="text-white hover:text-white">
              <span className="sr-only">{item.key}</span>
              { item.key === "facebook" &&  <FaFacebook className="h-6 w-6" aria-hidden="true" />}
              { item.key === "twitter" &&  <FaTwitter className="h-6 w-6" aria-hidden="true" />}
              { item.key === "tiktok" &&  <FaTiktok className="h-6 w-6" aria-hidden="true" />}
              { item.key === "instagram" &&  <FaInstagram className="h-6 w-6" aria-hidden="true" />}
              { item.key === "whatsapp" &&  <FaWhatsapp className="h-6 w-6" aria-hidden="true" />}
              { item.key === "youtube" &&  <FaYoutube className="h-6 w-6" aria-hidden="true" />}
              {/* <item.icon className="h-6 w-6" aria-hidden="true" /> */}
            </a>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-white">
          &copy; {new Date().getFullYear()} TuttoCapsule Ecuador, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
