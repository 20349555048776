
import en_US from './en.json';
import zh_CN from './zh.json';
import es_La from './es.json';

const resources = {
    en:{
        translation: en_US
    },
    zh: {
        translation: zh_CN
    },
    es: {
        translation: es_La
    }
};

export default resources;